/* for hiding process is not defined bug from console */

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&display=swap");
/* body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
  font-family: "Inter", sans-serif;
} */

/* body > iframe {
  pointer-events: none;
} */

* {
  font-family: "Inter", sans-serif;
}

.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

ul[role="listbox"][data-headlessui-state="open"] {
  min-height: 120px;
}

@media only screen and (max-width: 768px) {
  .pdfFileSelector {
     width: 300px;
     padding-left: 20px;
     padding-right: 20px;
     margin-left: 20px;
 }
}

.drawer {
  position: fixed;
  /* z-index: 9999; */
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 40 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 18rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.slick-slide {
  margin: 0 5px;
}

/* the parent */
.slick-list {
  margin: 0 27px;
}

.slick-next:before {
  color: #09529b !important;
  margin-left: -50px;
}

.slick-prev:before {
  color: #09529b !important;
  margin-right: -50px;
}

.cart-scrollbar {
  height: "300px";
  max-height: "300px";
  padding: "10px";
}

@media only screen and (max-width: 768px) {
  .cart-scrollbar {
    height: "800px";
    /* max-height: "300px"; */
    padding: "10px";
  }
}

/* .color{
  color: #111827; gary-900 
  color:#1f2937; gray-800
 color: #374151; gray-700
} */

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

@media print {
  .print_display {
    width: auto;
    height: auto;
    overflow: visible;
  }
}

@media screen {
  .print_display {
    overflow: hidden;
    height: 0;
  }
}

button:focus {
  outline: none;
}

.card_shadow {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
}

.reset {
  all: initial !important;
}

.logo {
  width: 180px;
}

.text-6px {
  font-size: 6px;
}
.text-8px {
  font-size: 8px;
}
.text-9px {
  font-size: 9px;
}
.text-10px {
  font-size: 10px;
}
.text-11px {
  font-size: 11px;
}

.guide p {
  padding-bottom: 10px;
}

button:focus {
  outline: none;
}

.stats-card {
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}
.top-selling-border {
  border-top: 0.4px solid;
}

.summary-card {
  border: 0.5px solid;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}

td-no-border-t:first-child {
  border: none;
}

.rich-text p {
  font-size: 14px;
}
.rich-text span {
  font-size: 14px;
}

.w-14 {
  width: 60px;
}
.h-14 {
  height: 60px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slider {
  overflow-x: scroll;
  white-space: nowrap;
}

.slider-container {
  display: inline-block;
}

.slider-item {
  display: inline-block;
  width: calc(100% / 2); /* show 2 slides per row, adjust as needed */
}

.facebook_color {
  background-color: #0084ff;
}
.b2b_color {
  background-color: #efefef;
}

.d-flex {
  display: flex !important;
}

.border-gray {
  border: #000 1px solid;
}

.border-xs {
  border: 0.6px solid #0230b1;
}

.border-xxs {
  border: 0.6px solid #0230b1;
}

.textarea {
  resize: none;
}

/* input[type="time"] {
  font-size: 14px;
  font-family: helvetica;
} */

.cart-border {
  border-bottom: 0.3px solid #5178ae;
  padding: 6px 0px;
}

.hide-svg svg {
  display: none;
}

.search-input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

input[type="color"] {
  border-radius: 4px;
  padding: 0;
  border: none;
}

input[type="color"]::-moz-color-swatch {
  border-radius: 4px;
  padding: 0;
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: 4px;
  padding: 0;
  border: none;
}

input[type="color"]::-webkit-color-swatch {
  border-radius: 4px;
  padding: 0;
  border: none;
}

/* 
.range-slider {
  position: relative;
  width: 200px;
  margin: 20px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #ccc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}

#range-value {
  position: absolute;
  top: -30px;
  transform: translateX(-50%);
  background: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
} */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 14px;
  }
}

textarea,
input[type="text"],
input[type="search"],
input[type="button"],
input[type="date"],
input[type="time"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 4px;
  background-color: transparent;
  text-align: left;
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reactTooltip {
  z-index: 10 !important;
}

.dropdowns {
  right: 0px;
}

@media only screen and (max-width: 600px) {
  .dropdowns-left {
    left: 0px;
  }
  .dropdowns-right {
    right: 0px;
  }
}

.topBar {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
}

/* SLider class  */

.rs-range {
  width: 150px;
  border-radius: 30px;
  -webkit-appearance: none;
}
.rs-range:focus {
  outline: none;
}
.rs-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #32a6f9;
  border-radius: 10px;
  border: 0px solid #010101;
}
.rs-range::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #32a6f9;
  border-radius: 10px;
  border: 0px solid #010101;
}
.rs-range::-webkit-slider-thumb {
  box-shadow: none;
  border: 0px solid #32a6f9;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.rs-range::-moz-range-thumb {
  box-shadow: none;
  border: 0px solid #32a6f9;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.rs-range::-moz-focus-outer {
  border: 0;
}

.rs-label {
  position: relative;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  background: #32a6f9;
  border-radius: 10%;
  text-align: center;
  margin-bottom: 7px;
  margin-left: -0px;
  left: attr(value);
  color: #fff;
  font-size: 12px;
}
.rs-label::after {
  display: block;
  font-size: 20px;
  letter-spacing: 0.07em;
  margin-top: -2px;
}
.pointer {
  height: 40px;
  width: 40px;
  background: red;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 12px 0;
  margin-top: -22px;
  position: relative;
  left: calc(0.5vw - 50px);
}

.ql-snow .ql-picker.ql-lineheight {
  width: 58px;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.0"]::before {
  content: "1.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.0"]::before {
  content: "1.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.2"]::before {
  content: "1.2";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.2"]::before {
  content: "1.2" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.5"]::before {
  content: "1.5";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.5"]::before {
  content: "1.5" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.6"]::before {
  content: "1.6";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.6"]::before {
  content: "1.6" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="1.8"]::before {
  content: "1.8";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="1.8"]::before {
  content: "1.8" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.0"]::before {
  content: "2.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.0"]::before {
  content: "2.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.4"]::before {
  content: "2.4";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.4"]::before {
  content: "2.4" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="2.8"]::before {
  content: "2.8";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="2.8"]::before {
  content: "2.8" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="3.0"]::before {
  content: "3.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="3.0"]::before {
  content: "3.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="4.0"]::before {
  content: "4.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="4.0"]::before {
  content: "4.0" !important;
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value="5.0"]::before {
  content: "5.0";
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value="5.0"]::before {
  content: "5.0" !important;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 100px;
}

.ql-container {
  height: calc(100% - 42px);
}

.w-fit {
  width: fit-content;
}

.p-2px {
  padding: 2px;
}

.opacity-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 12px;
  background: linear-gradient(90deg, #d9d9d9 24%, #000 62.73%);
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.opacity-slider:hover {
  opacity: 1;
}

.opacity-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 20px;
  background: #32a6f9;
  cursor: pointer;
}

.opacity-slider::-moz-range-thumb {
  width: 10px;
  height: 20px;
  background: #32a6f9;
  cursor: pointer;
}

/* .rbc-month-view {
  height: 100vh;
} */
.rbc-calendar {
  min-height: 500px;
  border: none !important;
}

.day-cell {
  border-left: none !important;

  /* height: 20px !important; */
  /* padding: 90px; */
}

.rbc-month-view {
  height: 40px;
}

.rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 80px !important;
}

.rbc-month-view {
  border: none !important;
}

.rbc-header {
  border-top: 1px solid #ddd !important;
}

.rbc-header + .rbc-header {
  border-left: none !important;
}

/* .rbc-time-header-gutter,
.rbc-time-content > .rbc-row > div:not(.rbc-time-gutter),
.rbc-header,
.rbc-month-view {
  border-right: none !important;
} */

/* .rbc-month-view .rbc-row:not(.rbc-header-row) > .rbc-day:nth-child(6) {
  background-color: blue;
}

.rbc-month-view .rbc-row:not(.rbc-header-row) > .rbc-day:nth-child(7) {
  background-color: red;
} */

.section-card {
  flex-basis: 25%;
  max-width: 33%;

  @media (max-width: 900px) {
    flex-basis: 75%;
    max-width: 75%;
    min-width: 75%;
  }
}

.countdowns-container {
  display: flex;
  gap: 2px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 8px;
  width: 100%; /* Adjust this width to the size of your card */
}

.countdown-card {
  flex: 0 0 auto;
  width: calc(50% - 4px); /* Adjust width to show two cards, the second one halfway */
  scroll-snap-align: start;
  max-width: 500px;
  min-width: 400px;
}


.todo-card {
  flex-basis: 40%;
  max-width: 48%;
  @media (max-width: 900px) {
    flex-basis: 100%;
    max-width: 100%;
    /* min-width: 75%; */
  }
}

.form-checkbox {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #92a8e6 !important;
}

.form-checkbox:checked {
  border-color: transparent !important;;
}